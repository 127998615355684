import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          
<path d="M905 2799 c-3 -4 2 -6 10 -5 21 3 28 13 10 13 -9 0 -18 -4 -20 -8z"/>
<path d="M899 2778 c-7 -25 -7 -48 0 -43 6 3 9 14 8 25 -1 17 -5 26 -8 18z"/>
<path d="M863 2725 c0 -13 3 -22 8 -20 9 6 8 45 -2 45 -4 0 -6 -11 -6 -25z"/>
<path d="M783 2725 c0 -8 4 -12 8 -9 4 2 9 -1 11 -8 3 -7 7 -4 12 7 4 11 4 16
0 12 -4 -4 -12 -2 -19 3 -8 7 -12 5 -12 -5z"/>
<path d="M990 2730 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M907 2723 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M829 2718 c-6 -12 -3 -58 4 -58 4 0 5 -9 2 -20 -4 -12 -2 -20 5 -20
15 0 26 33 12 38 -6 2 -8 10 -5 18 3 8 0 14 -6 14 -6 0 -8 7 -5 15 5 12 -2 23
-7 13z"/>
<path d="M759 2697 c-1 -4 -2 -13 -3 -19 -1 -7 -8 -12 -15 -10 -7 1 -10 -2 -5
-9 4 -7 -1 -19 -12 -27 -12 -9 -13 -13 -4 -10 12 5 15 -2 12 -34 -5 -43 -11
-46 -14 -5 -1 14 -5 24 -9 21 -4 -2 -5 -23 -2 -47 3 -28 1 -38 -5 -29 -6 9 -8
-15 -6 -68 1 -45 4 -84 7 -86 3 -3 8 16 11 41 4 25 11 45 17 45 5 0 9 9 9 19
0 10 7 21 15 25 17 6 20 33 5 38 -5 2 -10 23 -11 48 -2 62 0 70 11 70 5 0 7
-7 4 -15 -9 -21 5 -105 17 -105 16 0 23 31 9 40 -7 4 -13 30 -14 58 0 28 -4
54 -8 58 -5 4 -9 4 -9 1z m-33 -201 c-9 -19 -17 -42 -17 -53 -1 -15 -2 -15 -6
-2 -4 17 28 102 35 94 3 -2 -3 -20 -12 -39z"/>
<path d="M920 2684 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M970 2690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M797 2683 c-8 -17 -6 -63 3 -63 5 0 9 16 8 35 -1 35 -4 41 -11 28z"/>
<path d="M870 2674 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M1060 2680 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1000 2665 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M946 2661 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M887 2653 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M1040 2640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1447 2636 c-69 -26 -109 -75 -122 -152 -21 -129 90 -238 221 -218
43 6 44 7 44 43 l0 37 -59 -4 c-54 -4 -61 -2 -92 26 -31 28 -34 35 -34 87 0
52 3 59 34 87 30 26 39 29 86 26 53 -3 54 -3 58 27 3 17 1 34 -3 39 -15 15
-94 17 -133 2z"/>
<path d="M1660 2455 l0 -195 35 0 34 0 3 78 3 77 55 0 55 0 3 -77 3 -78 34 0
35 0 0 195 0 195 -35 0 -35 0 0 -80 0 -80 -60 0 -60 0 0 80 0 80 -35 0 -35 0
0 -195z"/>
<path d="M2010 2455 l0 -195 101 0 100 0 -3 38 -3 37 -50 1 c-79 2 -76 0 -73
42 l3 37 48 -2 47 -2 0 39 0 40 -51 0 -50 0 3 43 3 42 55 0 55 0 3 38 3 37
-95 0 -96 0 0 -195z"/>
<path d="M2329 2464 c-40 -102 -75 -190 -77 -195 -2 -5 15 -9 38 -9 33 0 43 4
47 20 5 18 14 20 73 20 60 0 69 -3 75 -20 5 -16 15 -20 46 -20 21 0 39 2 39 6
0 13 -154 384 -160 384 -3 0 -40 -84 -81 -186z m100 -35 c17 -50 14 -59 -19
-59 -34 0 -34 -1 -18 51 14 47 23 49 37 8z"/>
<path d="M2630 2455 l0 -195 35 0 34 0 3 73 3 72 61 5 c73 6 99 25 115 83 8
32 7 48 -6 83 -22 57 -59 74 -164 74 l-81 0 0 -195z m147 119 c26 -7 37 -35
29 -70 -6 -21 -12 -24 -56 -24 l-50 0 0 43 c0 24 3 47 7 50 8 9 41 9 70 1z"/>
<path d="M2930 2455 l0 -195 95 0 95 0 0 35 0 34 -57 3 -58 3 -3 158 -3 157
-34 0 -35 0 0 -195z"/>
<path d="M3496 2643 c-3 -4 7 -18 23 -32 85 -77 84 -226 -2 -305 -14 -13 -23
-27 -20 -30 9 -8 70 62 90 104 22 45 21 126 -1 169 -21 39 -81 102 -90 94z"/>
<path d="M3702 2467 c-40 -100 -73 -186 -73 -192 1 -5 16 -11 34 -13 28 -2 35
1 40 17 5 19 13 21 75 21 62 0 71 -2 77 -20 5 -15 15 -20 41 -20 19 0 34 4 34
8 0 12 -141 365 -149 374 -3 4 -39 -75 -79 -175z m94 -32 c22 -55 19 -65 -22
-65 -25 0 -35 4 -31 13 2 6 10 29 18 50 7 20 15 37 17 37 1 0 10 -16 18 -35z"/>
<path d="M4000 2454 l0 -197 86 6 c74 4 92 9 127 33 79 57 105 149 64 236 -38
84 -101 117 -224 118 l-53 0 0 -196z m191 90 c51 -49 44 -151 -15 -189 -14 -9
-43 -19 -66 -22 l-40 -6 0 128 0 128 47 -7 c31 -4 56 -15 74 -32z"/>
<path d="M976 2633 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M3228 2613 c-47 -51 -62 -93 -61 -171 1 -52 21 -98 50 -115 7 -4 13
-14 13 -21 0 -8 7 -17 16 -20 35 -14 36 -4 2 32 -42 44 -53 64 -62 115 -10 57
18 140 61 177 18 17 28 30 20 30 -7 0 -25 -12 -39 -27z"/>
<path d="M914 2616 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z"/>
<path d="M803 2605 c-3 -9 -3 -18 0 -21 9 -9 27 6 27 22 0 19 -19 18 -27 -1z"/>
<path d="M855 2610 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16 -10z"/>
<path d="M1080 2610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1016 2603 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M946 2593 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M880 2570 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"/>
<path d="M1130 2580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3257 2551 c-41 -61 -43 -97 -10 -160 28 -53 53 -76 53 -49 0 8 -11
24 -25 36 -22 19 -25 30 -25 83 0 54 3 64 30 91 34 33 36 38 16 38 -7 0 -25
-17 -39 -39z"/>
<path d="M3478 2557 c23 -23 35 -48 39 -75 7 -49 -12 -109 -38 -122 -10 -6
-19 -15 -19 -21 0 -16 56 33 69 59 21 45 6 152 -21 152 -5 0 -7 3 -5 8 7 10
-14 26 -38 30 -16 3 -14 -2 13 -31z"/>
<path d="M820 2559 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16
-21z"/>
<path d="M1050 2560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M980 2545 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M846 2533 c-9 -22 10 -41 25 -26 13 13 5 43 -10 43 -5 0 -12 -8 -15
-17z"/>
<path d="M910 2530 c0 -11 -7 -20 -15 -20 -8 0 -15 -9 -15 -19 0 -30 19 -35
26 -6 3 14 12 25 20 25 8 0 14 9 14 20 0 11 -7 20 -15 20 -8 0 -15 -9 -15 -20z"/>
<path d="M790 2520 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M1100 2530 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3317 2525 c-27 -20 -33 -77 -11 -114 10 -17 25 -31 33 -31 11 0 9 6
-7 23 -26 28 -29 88 -6 112 21 20 15 27 -9 10z"/>
<path d="M3420 2532 c0 -5 9 -17 20 -27 24 -22 25 -46 4 -86 -21 -42 -11 -47
16 -9 26 36 22 96 -7 117 -20 15 -33 17 -33 5z"/>
<path d="M1025 2520 c-11 -17 5 -32 21 -19 7 6 11 15 8 20 -7 12 -21 11 -29
-1z"/>
<path d="M956 2513 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M1150 2500 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M760 2480 c0 -11 -4 -20 -10 -20 -5 0 -10 -9 -10 -20 0 -11 7 -20 16
-20 11 0 14 6 9 20 -5 14 -2 20 9 20 9 0 16 9 16 20 0 11 -7 20 -15 20 -8 0
-15 -9 -15 -20z"/>
<path d="M814 2489 c-3 -6 -2 -15 4 -21 13 -13 32 -2 32 18 0 16 -26 19 -36 3z"/>
<path d="M1075 2490 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
-10z"/>
<path d="M990 2474 c0 -3 6 -12 14 -19 12 -12 15 -12 19 -1 3 8 3 17 0 20 -8
8 -33 8 -33 0z"/>
<path d="M1205 2470 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3360 2460 c0 -16 5 -21 23 -18 12 2 22 10 22 18 0 8 -10 16 -22 18
-18 3 -23 -2 -23 -18z"/>
<path d="M857 2464 c-4 -4 -7 -16 -7 -26 0 -20 25 -25 36 -7 8 13 -19 43 -29
33z"/>
<path d="M920 2450 c0 -11 6 -20 13 -20 19 0 27 10 20 26 -8 21 -33 17 -33 -6z"/>
<path d="M790 2440 c0 -10 6 -23 13 -27 8 -4 17 -16 20 -26 8 -22 31 -18 35 7
2 11 -4 19 -16 23 -11 3 -22 13 -25 24 -7 25 -27 25 -27 -1z"/>
<path d="M1122 2449 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M963 2424 c-7 -19 11 -39 26 -30 16 10 13 33 -4 40 -9 3 -18 -1 -22
-10z"/>
<path d="M1040 2430 c0 -15 21 -23 32 -12 6 6 2 11 -11 15 -12 3 -21 1 -21 -3z"/>
<path d="M890 2405 c0 -16 6 -25 15 -25 21 0 31 30 14 41 -23 14 -29 11 -29
-16z"/>
<path d="M1170 2420 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M724 2358 c1 -39 6 -63 14 -66 6 -2 12 -10 12 -17 0 -8 4 -17 10 -20
6 -4 8 10 6 35 -2 22 1 40 6 40 15 0 5 43 -11 48 -10 3 -12 -1 -8 -17 3 -11 1
-21 -5 -21 -6 0 -7 -8 -4 -17 4 -10 3 -14 -1 -9 -11 11 -11 60 0 67 11 8 2 39
-11 39 -5 0 -9 -28 -8 -62z"/>
<path d="M770 2400 c0 -10 7 -23 16 -28 13 -7 14 -15 4 -51 -10 -40 -10 -44
10 -58 12 -8 19 -21 16 -28 -2 -7 -2 -16 2 -20 4 -4 8 -11 8 -16 1 -5 2 -13 3
-19 0 -5 6 -10 11 -10 14 0 12 33 -2 49 -11 11 -9 11 9 2 12 -6 25 -16 29 -23
4 -6 3 -8 -3 -5 -6 4 -10 -3 -10 -17 0 -21 2 -23 17 -14 24 15 20 51 -7 63
-13 5 -25 16 -28 23 -2 7 -1 10 4 7 16 -9 21 11 7 30 -15 20 -38 12 -29 -10 8
-22 -10 -18 -24 5 -11 16 -10 18 2 14 18 -7 20 17 3 35 -11 10 -9 11 7 5 11
-4 26 -16 33 -26 17 -23 32 -23 32 0 0 10 5 23 10 28 14 14 2 44 -16 44 -8 0
-14 -7 -14 -15 0 -8 5 -15 10 -15 6 0 10 -4 10 -10 0 -5 -4 -10 -9 -10 -12 0
-81 69 -81 81 0 5 -4 9 -10 9 -5 0 -10 -9 -10 -20z"/>
<path d="M1092 2398 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M1012 2383 c4 -21 33 -25 33 -3 0 8 -8 16 -18 18 -14 3 -18 -1 -15
-15z"/>
<path d="M935 2380 c-10 -16 16 -43 28 -31 11 11 2 41 -12 41 -5 0 -13 -5 -16
-10z"/>
<path d="M1142 2369 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M1064 2359 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
<path d="M982 2333 c2 -10 10 -18 18 -18 8 0 16 8 18 18 2 12 -3 17 -18 17
-15 0 -20 -5 -18 -17z"/>
<path d="M1190 2340 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M910 2322 c0 -11 -7 -23 -16 -26 -13 -5 -14 -9 -4 -21 19 -23 43 -19
35 5 -5 14 -2 20 10 20 22 0 15 34 -8 38 -11 2 -17 -3 -17 -16z"/>
<path d="M1112 2319 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M1034 2309 c-8 -14 18 -31 30 -19 9 9 0 30 -14 30 -5 0 -12 -5 -16
-11z"/>
<path d="M960 2290 c0 -19 20 -27 33 -13 8 7 -11 33 -24 33 -5 0 -9 -9 -9 -20z"/>
<path d="M1165 2299 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M1085 2281 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
<path d="M1012 2264 c1 -9 9 -19 16 -22 9 -3 13 2 10 14 -1 9 -9 19 -16 22 -9
3 -13 -2 -10 -14z"/>
<path d="M1210 2269 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M936 2261 c-8 -12 21 -34 34 -26 8 5 8 11 -1 21 -13 16 -25 18 -33 5z"/>
<path d="M870 2251 c0 -12 29 -35 36 -28 11 10 -5 37 -21 37 -8 0 -15 -4 -15
-9z"/>
<path d="M1130 2250 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M780 2243 c0 -5 7 -18 15 -29 14 -18 15 -18 11 5 -3 23 -26 44 -26
24z"/>
<path d="M1060 2236 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"/>
<path d="M985 2230 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z"/>
<path d="M1180 2230 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M922 2218 c3 -7 13 -15 22 -16 12 -3 17 1 14 10 -3 7 -13 15 -22 16
-12 3 -17 -1 -14 -10z"/>
<path d="M1110 2210 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1042 2201 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z"/>
<path d="M913 2175 c0 -14 3 -25 8 -25 5 0 6 -8 2 -17 -3 -10 -3 -15 2 -11 4
4 14 0 21 -10 10 -12 10 -7 -1 21 -11 25 -12 37 -4 37 8 0 8 4 -1 15 -18 22
-27 18 -27 -10z"/>
<path d="M974 2185 c3 -8 0 -15 -6 -15 -9 0 -9 -3 0 -12 9 -9 15 -8 26 7 16
22 12 35 -11 35 -9 0 -13 -6 -9 -15z"/>
<path d="M1150 2190 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1080 2180 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M3692 2178 c-7 -7 -12 -24 -12 -38 0 -46 43 -67 70 -35 14 17 0 21
-16 5 -18 -18 -48 13 -39 39 6 19 12 22 37 17 26 -5 29 -4 18 9 -15 18 -42 19
-58 3z"/>
<path d="M3786 2175 c-32 -33 -6 -89 38 -83 26 4 45 52 30 76 -15 23 -48 27
-68 7z m54 -9 c0 -2 3 -11 6 -19 8 -22 -25 -51 -42 -36 -15 12 -19 41 -7 52 7
8 43 10 43 3z"/>
<path d="M3880 2138 c0 -26 5 -48 10 -48 6 0 10 18 10 39 0 24 5 41 14 44 21
8 26 -2 26 -46 0 -22 3 -37 8 -33 8 8 9 14 11 51 1 18 7 25 20 25 16 0 21 -9
24 -42 4 -39 4 -38 3 7 l-1 50 -62 0 -63 1 0 -48z"/>
<path d="M4090 2137 c0 -30 4 -46 10 -42 6 3 10 22 10 41 0 27 4 34 20 34 17
0 20 -7 20 -40 0 -22 5 -40 10 -40 12 0 13 69 2 87 -4 7 -22 12 -40 10 -31 -2
-32 -3 -32 -50z"/>
<path d="M4201 2177 c-16 -16 -14 -62 4 -77 8 -7 24 -10 35 -7 24 6 26 -5 5
-23 -12 -10 -19 -10 -27 -2 -17 17 -33 15 -18 -3 15 -18 42 -19 58 -3 7 7 12
37 12 68 0 53 -1 55 -29 57 -15 1 -33 -3 -40 -10z m59 -37 c0 -29 -25 -43 -44
-25 -9 9 -16 20 -16 25 0 5 7 16 16 25 19 18 44 4 44 -25z"/>
<path d="M1020 2170 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M1130 2160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M880 2151 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"/>
<path d="M1070 2150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M960 2140 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M1007 2137 c-3 -8 -1 -20 4 -28 8 -13 13 -6 10 14 0 4 4 7 10 7 5 0
7 5 4 10 -9 14 -22 12 -28 -3z"/>
<path d="M1058 2123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M970 2109 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1050 2101 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M3630 2100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4040 2100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
